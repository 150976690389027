// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


require('jquery')
require('bootstrap')
//import 'bootstrap'
import('bootstrap-select')
import('../javascripts/stripe_subscription')
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(function() {
    var toggle_access = function(parent,current_target_class) {
        var current_index =  $(parent).prop('selectedIndex');

        if(current_target_class) {
            var target_object = $(current_target_class)
            var can_disable = target_object.is("button") || target_object.is('input[type="submit"]')
            var is_disabled = current_index > 0 ? false : true
            console.log("current_target_class="+current_target_class+" index="+current_index +" can_disable="+can_disable)

            if(can_disable) {
                console.log("disabling target class: "+current_target_class);
                window.target_class =  $(current_target_class)
                $(current_target_class).prop('disabled',is_disabled);
            }
        }

        return current_index
    }

    $(".checkAll").click(function(){
        $('input:checkbox').not(this).prop('checked', this.checked);
    });

    $("select.toggle-access").each(function( index ) {
        var current_index =  $(this).prop('selectedIndex');
        var current_target_class = $(this).data("toggle-access-selector");

        toggle_access(this,current_target_class)
        $(this).change(function() {
            toggle_access(this,current_target_class)
        })
    });
});

